import React from "react";

// Customizable Area Start
import { Box, Typography, Button, Grid, withStyles,  } from "@material-ui/core";
import {Close} from "@material-ui/icons";
import { logo } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import i18n from "../../../components/src/TranslateLanguage";
// Customizable Area End

import PrivayPolicyController, {
  Props
} from "./PrivacyPolicyController";

export class PrivayPolicy extends PrivayPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props
    return (
      <Box data-test-id="id1"  className={classes.mainWrapper}>
            <Box id="id22" className={classes.termsCondiWrapper}>
              <Grid className="id33" container alignItems="center" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                <Grid data-test-id="id44" item md={6} sm={12} className={classes.termsCol}>
                  <Box id="id55" className={classes.termsCformImageol}>
                      <Box data-test-id="id66" className={classes.loginImage}>
                          <img src={logo} width={300} alt="logo" className={classes.formImageImg}  /> 
                      </Box>
                  </Box>
                </Grid>
                <Grid className="id77" item md={6} sm={12}>
                  <Box data-test-id="id88" className={classes.termsCondiItem}>
                    <Box className={classes.closebtn} data-test-id="close-button" onClick={() => this.props.navigation.navigate('LandingPage')}>
                        <a data-test-id="id109" href="/#">
                            <Close className={classes.blackColor} />
                        </a>
                    </Box>
                    <Box data-test-id="id110" className={classes.termsCondiBlock} dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                        <Box>
                            <Typography variant="h4" className={classes.termsCondiBlockHeading}>{i18n.t('PRIVACY_POLICY')}</Typography>
                            <Typography variant="subtitle1" className={classes.termsCondiBlockSubHeading}>{i18n.t('UPDATED_ON')}</Typography>
                        </Box>
                        {
                          this.state.flag ? (
                            <Box data-test-id="id12" className={`${classes.termsCondiBlockContent} ${classes.loaderClass}`}>
                            <CircularProgress />
                            </Box>
                          ) : (
                            <Box data-test-id="id12" className={classes.termsCondiBlockContent}>
                          {
                            this.state.termsCondsData.map((item: any, index: number) => {
                              return(
                                <Typography id="id13" key={index} variant="body1" className={classes.termsCondiBlockInnerContent} dangerouslySetInnerHTML={{ __html: item?.description }}></Typography>
                              )
                            })
                          }
                        </Box>
                          )
                        }
                        <Box className={classes.termsCondiButton} dir="ltr">
                        <Button data-test-id="understood-button-test" onClick={() => this.onCallPressButton()} disabled={this.state.getDisbled} variant="outlined" className={classes.termsCondiButtonBtn} >{i18n.t('UNDERSTOOD')}</Button>
                        </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  mainWrapper: {
    padding: "48px",
    backgroundColor: "rgba(55, 101, 127, 0.2)",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    minHeight: "100vh",
    '@media (max-width: 991px)': {
      padding: "0",
    },
  },
  blackColor: {
    fill: "black"
  },
  termsCondiWrapper: {
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "#fff",
  },
  termsCol: {
    width: "100%",
  },
  termsCformImageol: {
    textAlign: "center" as const,
    padding: "60px",
    backgroundColor: "#37657f",
    height: "100%",
    borderRadius: "20px 0 0 20px",
    '@media (max-width: 991px)': {
      borderRadius: "20px 20px 0 0",
    }
  },
  closebtn: {
    position: 'absolute' as const,
    right: '40px',
    top: '0px',
    background:' rgba(217, 217, 217, 0.50)',
    width: '35px',
    height: '35px',
    textAlign: 'center' as const,
    borderRadius:' 5px',
    justifyContent: 'center',
    display:' flex',
    alignItems: 'center',
  },
  formImageImg: {
    width: "100%",
    maxWidth: "300px",
  },
  loginImage: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    maxHeight: "78vh",
    backgroundColor: "#fff",
    display: "flex",
    borderRadius: 14,
    width: "100%",
    minHeight: "78vh",
    '@media (max-width: 575px)': {
      minHeight: "60vh",
    }
  },
  termsCondiItem: {
    position: "relative" as const,
    // height: "100%",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  termsCondiBlock: {
    margin: "auto",
    maxWidth: "80%",
    padding: "70px 0",
    position: "relative" as const,
    '@media (max-width: 991px)': {
      maxWidth: "90%",
    }
  },
  termsCondiBlockHeading: {
    fontSize: 32,
    margin: "0 0 8px",
    color: "#000",
    fontFamily: "Corbel V2",
    fontWeight: "bold" as const,
    fontStyle: "normal",
  },
  termsCondiBlockSubHeading: {
    fontWeight: "normal" as const,
    fontFamily: "Corbel V2",
    color: "#000",
    fontStyle: "normal",
    fontSize: 16,
  },
  loaderClass: {
    display : 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  termsCondiBlockContent: {
    height: "calc(100vh - 520px)",
    overflowY:"auto" as const,
    padding: "10px",
    margin: "40px 0",
    overflowX:"hidden" as const,
    '&::-webkit-scrollbar': {
      width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
      backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: 'rgb(32, 94, 131, 0.5)',
  }
  },
  termsCondiButton: {
    display: "flex",
    gap: "12px",
    alignItems: "flex-end",
    padding: "20px 0 0",
    justifyContent: "flex-end",
  },
  termsCondiBlockInnerContent: {
    fontSize: 16,
    fontWeight: "normal" as const,
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 30px",
    fontStyle: "normal",
    fontFamily: "Corbel V2",
    "& br":{
      display:"none"
    },
    "& li":{
      marginBottom:10
    }
  },
  termsCondiButtonBtn: {
      width: "100%",
      backgroundColor: "#37657f",
      padding: "10px",
      textTransform: 'capitalize' as const,
      color: "#fff",
      borderRadius: 8,
      border: "1px solid #37657f",
      fontFamily: "Corbel V2",
      letterSpacing: 0.5,
      cursor: "pointer",
      maxWidth: "225px",
      "& span":{
        fontSize: 16,
        fontWeight: 700,
      },
      "&:hover" : {
        "color" : "#37657f"
      },
    },
  termsCondiButtonBtnOutline: {
    color: "#37657f",
    background: "rgba(213, 225, 255, 0.5)",
    textTransform: 'capitalize' as const,
    padding: "10px",
    fontWeight: 600,
    borderRadius: 5,
    border: "1px solid #37657f",
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
    letterSpacing: 0.5,
    width: "100%",
    cursor: "pointer",
  } 
}
//@ts-ignore
export default withRouter(withStyles(webStyles)(PrivayPolicy));
// Customizable Area End
