import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating/Rating";
import {
  createTheme,
  styled,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "../assets/css/Fashiondesign.web.css";
const baseURL = require("../../../framework/src/config.js").baseURL;
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
// Customizable Area End

import CategoriessubcategoriesController, {
  Props,
} from "./CategoriessubcategoriesController";
import { google_maps_img, info_img, userNoIcon } from "./assets";
import SearchScreen from "../../../components/src/SearchScreen.web";
import Maps from "../../maps/src/Maps.web";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#34D399",
      contrastText: "#fff",
    },
  },
});

const TooltipStyle = withStyles({
  tooltip: {
    color: "rgba(0, 0, 0, .67);",
    backgroundColor: "white",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "18px",
    maxWidth: "none",
    padding: "10px",
  },
})(Tooltip);

export default class FashionDesign extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
  }

  modalRender = () => {
    return (
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={this.state.open}
        aria-labelledby="form-dialog-title"
      >
        <ModalStyle>
          <DialogContent>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Box className="modalHeader">
                  <Button
                    id="modalDeleteVisible"
                    data-test-id="close_button_modal_modalDeleteVisible"
                    onClick={() => {
                      this.handleClose();
                    }}
                    type="button"
                    className="closeDeleteBtn"
                  >
                    <span>×</span>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography className="Survey">{i18nJs.t("Survey")}</Typography>
              </Grid>
            </Grid>
            {this.state.getQuestionloding ? (
              <Box className="survey-loader">
                <CircularProgress />
              </Box>
            ) : (
              this.state?.studentSurvey?.map((obj: any, index: number) => {
                const isArabic = i18nJs.locale === "ar";
                return (
                  <Grid container className="main-container-survay" key={index}>
                    <Grid item xs={12}>
                      <FormLabel className="question-label">
                      {isArabic ? obj.question_ar : obj.question}
                      </FormLabel>
                      <textarea
                        placeholder={i18nJs.t("Type here")}
                        value={obj.answer}
                        data-test-id={`textarea-survey-${index}`}
                        className="textarea-survey"
                        onChange={(event) => this.onChangeSurvay(event, index)}
                      />
                    </Grid>
                  </Grid>
                );
              })
            )}
          </DialogContent>
          <div className="button-position">
            <div className="fashion-info-button-second">
              <Button
                className="btn button-outline btn-one"
                data-test-id="nothanksbutton"
                onClick={() => this.handleClose()}
              >
                {i18nJs.t("No, thanks")}
              </Button>
              <Button
                className="btn button-green btn-two"
                data-test-id="submitData-survay"
                onClick={() => this.submitData()}
              >
                {i18nJs.t("Submit rating")}
              </Button>
            </div>
          </div>
        </ModalStyle>
      </Dialog>
    );
  };

  renderImageAndDes = () => {
    return (
      <BoxStyle>
        {this.state.singleSessionData?.attributes?.description && (
          <Box className="lesson-description-container">
            <Typography className="lesson-description-title margin-80">
             {i18nJs.t('Lesson Description')}*
            </Typography>
            <Typography className="lesson-description-information">
              {this.state.singleSessionData?.attributes?.description}
            </Typography>
          </Box>
        )}
        {this.state.singleSessionData?.attributes?.lesson_image && (
          <Box>
            <Typography className="lesson-attachment-title">
              {i18nJs.t('Lesson Attachment')}
            </Typography>
            <Box className="attachment-container">
              <Box className="text-and-icon">
                <Box className="attachment-icon-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16 6.74722V17.3272C16 19.4172 14.47 21.2772 12.39 21.4772C10 21.7072 8 19.8372 8 17.4972V5.13722C8 3.82722 8.94 2.63722 10.24 2.50722C11.74 2.35722 13 3.52722 13 4.99722V15.4972C13 16.0472 12.55 16.4972 12 16.4972C11.45 16.4972 11 16.0472 11 15.4972V6.74722C11 6.33722 10.66 5.99722 10.25 5.99722C9.84 5.99722 9.5 6.33722 9.5 6.74722V15.3572C9.5 16.6672 10.44 17.8572 11.74 17.9872C13.24 18.1372 14.5 16.9672 14.5 15.4972V5.16722C14.5 3.07722 12.97 1.21722 10.89 1.01722C8.51 0.787216 6.5 2.65722 6.5 4.99722V17.2672C6.5 20.1372 8.6 22.7072 11.46 22.9772C14.75 23.2772 17.5 20.7172 17.5 17.4972V6.74722C17.5 6.33722 17.16 5.99722 16.75 5.99722C16.34 5.99722 16 6.33722 16 6.74722Z"
                      fill="#37657F"
                    />
                  </svg>
                </Box>
                <Box>
                  <Typography className="attachment-image-name">
                    {this.renderImageName(
                      this.state.singleSessionData.attributes.lesson_image.url
                    )}
                  </Typography>
                  <Typography className="attachment-image-size">
                    2 MB
                  </Typography>
                </Box>
              </Box>
              <Box
                className="attachment-icon-container curser-pointer"
                data-test-id="attachment-icon-container"
                onClick={() =>
                  this.handleDownload(
                    baseURL +
                      this.state.singleSessionData?.attributes?.lesson_image
                        ?.url
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 15.575C11.8667 15.575 11.7417 15.554 11.625 15.512C11.5083 15.4707 11.4 15.4 11.3 15.3L7.7 11.7C7.51667 11.5167 7.425 11.2833 7.425 11C7.425 10.7167 7.51667 10.4833 7.7 10.3C7.88333 10.1167 8.12067 10.0207 8.412 10.012C8.704 10.004 8.94167 10.0917 9.125 10.275L11 12.15V5C11 4.71667 11.096 4.479 11.288 4.287C11.4793 4.09567 11.7167 4 12 4C12.2833 4 12.521 4.09567 12.713 4.287C12.9043 4.479 13 4.71667 13 5V12.15L14.875 10.275C15.0583 10.0917 15.296 10.004 15.588 10.012C15.8793 10.0207 16.1167 10.1167 16.3 10.3C16.4833 10.4833 16.575 10.7167 16.575 11C16.575 11.2833 16.4833 11.5167 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.4707 12.375 15.512C12.2583 15.554 12.1333 15.575 12 15.575ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V16C4 15.7167 4.09567 15.479 4.287 15.287C4.479 15.0957 4.71667 15 5 15C5.28333 15 5.521 15.0957 5.713 15.287C5.90433 15.479 6 15.7167 6 16V18H18V16C18 15.7167 18.096 15.479 18.288 15.287C18.4793 15.0957 18.7167 15 19 15C19.2833 15 19.5207 15.0957 19.712 15.287C19.904 15.479 20 15.7167 20 16V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6Z"
                    fill="#37657F"
                  />
                </svg>
              </Box>
            </Box>
          </Box>
        )}
      </BoxStyle>
    );
  };

  aboutDescription = () => {
    return (
      <>
        {this.state.filtercarddata?.attributes?.description && (
          <>
            <div className="fashion-heading">
              <h6>{i18nJs.t('About')}</h6>
            </div>
            <div className="home-inner-content">
              <p>{this.state.filtercarddata.attributes.description}</p>
            </div>
          </>
        )}
      </>
    );
  };

  getAssessmentLink = () => {
    return this.state.assessMent ? (
      <Link
        to={`/Assessmenttest/${this.state.assessMent}`}
        className="assessLink"
      >
        {i18nJs.t('Assessment Test')}
      </Link>
    ) : (
      i18nJs.t('Assessment Test')
    );
  };

  dialogModalrender = () => (
    <Dialog
      open={this.state.openMap}
      onClose={this.handleMapDialogClose}
      maxWidth="lg"
      fullWidth
      data-test-id="dialog-modal"
    >
      <DialogTitle data-test-id="dialog-title">
        {this.state.city ? this.state.city : "Place name"}
        <Box sx={{ fontSize: "small" }} data-test-id="dialog-street">
          {this.state.street ? this.state.street : ""}
        </Box>
        <Box
          className="close-btn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "15px",
          }}
        >
          <span data-test-id="close-button" onClick={this.handleMapDialogClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </span>
        </Box>
      </DialogTitle>
      <DialogContent data-test-id="dialog-content">
        <Maps
          navigation={undefined}
          id=""
          addressProp={this.state.filtercarddata.attributes?.address}
        />
      </DialogContent>
    </Dialog>
  );

  sessionListRender = () => {
    return this.state.sessionList.length ? (
      <div className="fashion-block">
        <div className="fashion-heading">
          <h6>{i18nJs.t("All Sessions")}</h6>
        </div>
        {this.state.sessionList.map((obj: any, ind: number) => (
          <div
            className={`${
              this.state.singleSessionData.id === obj.id
                ? "home-lesson-activeted"
                : "home-lesson"
            } ${obj.attributes.seen_lesson && "testseen"}`}
            data-test-id={`faqid${ind}`}
            key={ind}
            onClick={() => this.addSession(obj, ind)}
          >
            <div className="home-lesson-content" id="checkinghai">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" />
                </svg>
              </span>
              <p>
                {obj.attributes.title
                  ? obj.attributes.title
                  : obj.attributes.description}
              </p>
            </div>
            <StyledSpan >
              {obj.attributes.seen_lesson && (
                <>
                  <span style={{ marginBottom: "1px" }}>
                    <CheckCircleOutlineIcon
                      style={{
                        color: "#34D399",
                        fontSize: "17px",
                        marginRight: "2px",
                      }}
                    />{" "}
                  </span>
                  <span>{i18nJs.t('Completed')}</span>{" "}
                  <DummyBox >
                    |
                  </DummyBox>
                </>
              )}
              <span style={{paddingLeft:'10px'}}>
                {obj.attributes.duration} 
              </span>{i18nJs.t('mins')}
            </StyledSpan>
          </div>
        ))}
      </div>
    ) : (
      ""
    );
  };

  renderProfileIconLecture = () => {
    return (
      <img
        src={
          this.state.filtercarddata?.attributes?.profile_picture?.url
            ? baseURL + this.state.filtercarddata.attributes.profile_picture.url
            : userNoIcon
        }
        alt="user-image"
        width={35}
        height={35}
        data-test-id="lecture-profile-icon"
      />
    );
  };

  renderReviewSection = () => {
    return(
      <div
        className={`fashion-review-block ${this.state.currentInterfaceLanguage === 'ar' && "fashion_review_arabic_block"} ${
          this.state.sessionList.length
            ? ""
            : "fashion-review-block-length"
        }`}
      >
        <div className="fashion-heading">
          <h6>{i18nJs.t('Write a Review')}</h6>
        </div>
        <StyledRating
          disabled={this.state.isReviewDone ? true : false}
          value={this.state.rating}
          data-test-id="writereview"
          size="large"
          onChange={(event, newValue: any) => {
            this.onchangeRating(newValue);
          }}
        />
        <div className="fashion-review-content">
          <h6>{i18nJs.t('Review')}</h6>
          <form>
            <input
              disabled={this.state.isReviewDone ? true : false}
              type="text"
              style={this.state.isReviewDone ? {pointerEvents: 'none'}: undefined}
              data-test-id="write-review-input"
              placeholder="Write here"
              value={this.state.writereview}
              onChange={(event: any) => {
                this.onChangeWriteReview(event);
              }}
            />
            <button
              disabled={this.state.isReviewDone ? true : false}
              type="button"
              style={{fontSize:16}}
              className={this.state.isReviewDone ?"btn":"btn button-green"}
              data-test-id="submitreview"
              onClick={() => this.onClickSubmitData()}
            >
              {i18nJs.t('Submit Review')}
            </button>
          </form>
        </div>
      </div>
    )
  }

  render() {
    let lessonsCount = this.state.filtercarddata.attributes?.no_of_lessons;
    let LessonsCountLength =
      this.state.filtercarddata.attributes?.no_of_lessons &&
      lessonsCount.toString();
    let FinalLessonsCountLength =
      this.state.filtercarddata.attributes?.no_of_lessons &&
      LessonsCountLength.length;
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title={this.state.filtercarddata?.attributes?.course_name}
        >
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <div className="content-wrapper">
              <div className="home-search-block">
                <SearchScreen
                  searchValue={this.state.searchValue}
                  onClickSearchModalOpen={this.onClickSearchModalOpen}
                  divRef={this.state.divRef}
                  onsearchValue={this.onsearchValue}
                  searchHistory={this.state.searchHistory}
                  onClickSearchModalClose={this.onClickSearchModalClose}
                  onClickSearchValue={this.onClickSearchValue}
                  searchHistoryData={this.state.searchHistoryData}
                  selectHistoryDelete={this.selectHistoryDelete}
                  clearAllHistory={this.clearAllHistory}
                />
              </div>
              {this.modalRender()}
              <div className="fashion-page">
                <div className="fashion-content-image">
                  <video
                    className="fashion-content-video"
                    ref={this.state.videoRef}
                    src={
                      baseURL +
                      this.state.singleSessionData?.attributes?.lesson_video
                        ?.url
                    }
                    controls
                    data-test-id="plaupausevideo"
                    onPlay={this.handleVideoPlay}
                    onPause={this.handleVideoPause}
                  ></video>
                  <div>
                    <div
                      className={
                        this.state.isPlaying
                          ? "play-pause-block"
                          : "play-pause-block  start-video"
                      }
                    >
                      <span
                        data-test-id="playPause"
                        onClick={this.togglePlayPause}
                      >
                        <svg
                          className="paly-button"
                          xmlns="http://www.w3.org/2000/svg"
                          width={50}
                          height={50}
                          viewBox="0 0 112 112"
                          fill="none"
                        >
                          <path
                            d="M42.0382 81.1248L81.1215 55.9998L42.0382 30.8748V81.1248ZM55.9965 111.833C48.2729 111.833 41.0145 110.368 34.2215 107.436C27.4284 104.505 21.5194 100.527 16.4944 95.5019C11.4694 90.4769 7.49127 84.5679 4.56002 77.7748C1.62877 70.9818 0.163147 63.7235 0.163147 55.9998C0.163147 48.2762 1.62877 41.0179 4.56002 34.2248C7.49127 27.4318 11.4694 21.5228 16.4944 16.4978C21.5194 11.4728 27.4284 7.49463 34.2215 4.56338C41.0145 1.63213 48.2729 0.166504 55.9965 0.166504C63.7201 0.166504 70.9784 1.63213 77.7715 4.56338C84.5646 7.49463 90.4736 11.4728 95.4986 16.4978C100.524 21.5228 104.502 27.4318 107.433 34.2248C110.364 41.0179 111.83 48.2762 111.83 55.9998C111.83 63.7235 110.364 70.9818 107.433 77.7748C104.502 84.5679 100.524 90.4769 95.4986 95.5019C90.4736 100.527 84.5646 104.505 77.7715 107.436C70.9784 110.368 63.7201 111.833 55.9965 111.833ZM55.9965 100.667C68.4659 100.667 79.0277 96.3394 87.6819 87.6853C96.3361 79.0311 100.663 68.4693 100.663 55.9998C100.663 43.5304 96.3361 32.9686 87.6819 24.3144C79.0277 15.6603 68.4659 11.3332 55.9965 11.3332C43.527 11.3332 32.9652 15.6603 24.3111 24.3144C15.6569 32.9686 11.3298 43.5304 11.3298 55.9998C11.3298 68.4693 15.6569 79.0311 24.3111 87.6853C32.9652 96.3394 43.527 100.667 55.9965 100.667Z"
                            fill="#F8FAFC"
                          />
                        </svg>
                        <svg
                          className="pause-buton"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-72V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24zm112 0V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24z" />
                        </svg>
                      </span>
                    </div>
                    <div className="restore-button">
                      <span
                        data-test-id="backvideo"
                        onClick={() => this.handleSkipBackward(10)}
                        className="back-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={40}
                          height={40}
                          viewBox="0 0 80 80"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M39.9942 13.8133C56.8275 13.8133 70.4608 27.7133 69.9942 44.6799C69.5608 60.3133 56.4942 73.3799 40.8608 73.8133C23.8942 74.2799 9.99416 60.6466 9.99416 43.8133C9.99416 38.4466 11.4275 33.4466 13.8608 29.0799C14.9275 27.1799 17.5608 26.8799 19.0942 28.4133C20.1608 29.4466 20.4275 31.0466 19.6942 32.3466C17.7942 35.7466 16.6608 39.6466 16.6608 43.8133C16.6608 56.8133 27.2942 67.3133 40.3275 67.1466C52.7275 66.9799 63.1608 56.5466 63.3275 44.1466C63.4942 31.0799 52.9942 20.4799 39.9942 20.4799L39.9942 26.4466C39.9942 27.9466 38.1942 28.6799 37.1608 27.6466L27.8608 18.3466C27.1942 17.6799 27.1942 16.6466 27.8608 15.9799L37.1608 6.67992C38.1942 5.61325 39.9942 6.34658 39.9942 7.84658L39.9942 13.8133ZM46.6608 43.8133C46.6608 47.4799 43.6608 50.4799 39.9942 50.4799C36.3275 50.4799 33.3275 47.4799 33.3275 43.8132C33.3275 40.1466 36.3275 37.1466 39.9942 37.1466C43.6608 37.1466 46.6608 40.1466 46.6608 43.8133Z"
                            fill="#F8FAFC"
                          />
                        </svg>
                      </span>
                      <span
                        data-test-id="forwordvideo"
                        onClick={() => this.handleSkipForward(10)}
                        className="next-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={40}
                          height={40}
                          viewBox="0 0 80 80"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M39.9942 13.8133C56.8275 13.8133 70.4608 27.7133 69.9942 44.6799C69.5608 60.3133 56.4942 73.3799 40.8608 73.8133C23.8942 74.2799 9.99416 60.6466 9.99416 43.8133C9.99416 38.4466 11.4275 33.4466 13.8608 29.0799C14.9275 27.1799 17.5608 26.8799 19.0942 28.4133C20.1608 29.4466 20.4275 31.0466 19.6942 32.3466C17.7942 35.7466 16.6608 39.6466 16.6608 43.8133C16.6608 56.8133 27.2942 67.3133 40.3275 67.1466C52.7275 66.9799 63.1608 56.5466 63.3275 44.1466C63.4942 31.0799 52.9942 20.4799 39.9942 20.4799L39.9942 26.4466C39.9942 27.9466 38.1942 28.6799 37.1608 27.6466L27.8608 18.3466C27.1942 17.6799 27.1942 16.6466 27.8608 15.9799L37.1608 6.67992C38.1942 5.61325 39.9942 6.34658 39.9942 7.84658L39.9942 13.8133ZM46.6608 43.8133C46.6608 47.4799 43.6608 50.4799 39.9942 50.4799C36.3275 50.4799 33.3275 47.4799 33.3275 43.8132C33.3275 40.1466 36.3275 37.1466 39.9942 37.1466C43.6608 37.1466 46.6608 40.1466 46.6608 43.8133Z"
                            fill="#F8FAFC"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="fashion-item-content">
                  <div className="home-rating-block">
                    <StyledRatingShow
                      name="read-only"
                      value={
                        this.state.filtercarddata.attributes?.average_rating ||
                        0
                      }
                      readOnly
                      precision={0.5}
                    />
                    <span>
                      {this.state.filtercarddata.attributes?.average_rating?.toFixed(
                        1
                      )}
                    </span>
                  </div>
                  {this.state.filtercarddata.attributes?.course_name && (
                    <Box className="ongoing_button">
                      <h3 className="fashion-course_name">
                        {this.state.filtercarddata.attributes.course_name}
                      </h3>

                      {!this.isOngoingCourse() && (
                        <Button
                          style={{
                            borderRadius: 8,
                            background: "#34D399",
                            padding: "8px",
                            boxShadow: "none",
                            textTransform: "none",
                            lineHeight: "22px",
                          }}
                          variant="contained"
                          data-test-id="dialog"
                        >
                          <Box alignItems="center" style={{ display: "flex" }}>
                            <RefreshIcon style={{ color: "#FFFFFF" }} />
                            <Box
                              style={{
                                marginLeft: "5px",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              {i18nJs.t("Ongoing")}
                            </Box>
                          </Box>
                        </Button>
                      )}
                    </Box>
                  )}
                  {this.state.filtercarddata.attributes?.category_id && (
                    <p className="fashion-title">
                      {this.getCategoriesNameFashion(
                        this.state.filtercarddata.attributes.category_id
                      )}
                    </p>
                  )}
                  {this.state.filtercarddata.attributes?.information && (
                    <span className="fashion-information">
                      {this.state.filtercarddata.attributes.information}
                    </span>
                  )}
                  <div className="home-user-block">
                    {this.renderProfileIconLecture()}
                    {this.state.filtercarddata.attributes?.lecturer && (
                      <p className="fashion-lecturer">
                        {i18nJs.t("By")}{" "}
                        {this.state.filtercarddata.attributes.lecturer}
                      </p>
                    )}
                  </div>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={8} style={{ marginTop: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          lg={3}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {this.state.filtercarddata?.attributes
                            ?.total_duration_of_lessons && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.66667 1.66602C5.75 1.66602 5 2.41602 5 3.33268V5.97435C5 6.41602 5.175 6.84102 5.49167 7.15768L8.33333 9.99935L5.48333 12.8493C5.175 13.166 5 13.591 5 14.0327V16.666C5 17.5827 5.75 18.3327 6.66667 18.3327H13.3333C14.25 18.3327 15 17.5827 15 16.666V14.0327C15 13.591 14.825 13.166 14.5167 12.8577L11.6667 9.99935L14.5083 7.16602C14.825 6.84935 15 6.42435 15 5.98268V3.33268C15 2.41602 14.25 1.66602 13.3333 1.66602H6.66667ZM13.3333 13.7493V15.8327C13.3333 16.291 12.9583 16.666 12.5 16.666H7.5C7.04167 16.666 6.66667 16.291 6.66667 15.8327V13.7493L10 10.416L13.3333 13.7493ZM6.66667 4.16602V6.24935L10 9.58268L13.3333 6.24935V4.16602C13.3333 3.70768 12.9583 3.33268 12.5 3.33268H7.5C7.04167 3.33268 6.66667 3.70768 6.66667 4.16602Z"
                                  fill="#37657F"
                                />
                              </svg>
                              <p className="mr-25">
                                {this.convertMinutes(
                                  this.state.filtercarddata.attributes.total_duration_of_lessons.toFixed(
                                    0
                                  )
                                )}
                              </p>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={3}
                          style={{ display: "flex", alignItems: "center", gap: "14px" }}
                        >
                          {this.state.filtercarddata?.attributes
                            ?.no_of_lessons && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.4999 2.5H2.49992C1.58325 2.5 0.833252 3.25 0.833252 4.16667V14.1667C0.833252 15.0833 1.58325 15.8333 2.49992 15.8333H6.66658V16.6667C6.66658 17.125 7.04158 17.5 7.49992 17.5H12.4999C12.9583 17.5 13.3333 17.125 13.3333 16.6667V15.8333H17.4999C18.4166 15.8333 19.1583 15.0833 19.1583 14.1667L19.1666 4.16667C19.1666 3.24167 18.4166 2.5 17.4999 2.5ZM16.6666 14.1667H3.33325C2.87492 14.1667 2.49992 13.7917 2.49992 13.3333V5C2.49992 4.54167 2.87492 4.16667 3.33325 4.16667H16.6666C17.1249 4.16667 17.4999 4.54167 17.4999 5V13.3333C17.4999 13.7917 17.1249 14.1667 16.6666 14.1667ZM8.74992 11.7917L12.0666 9.89167C12.6249 9.56667 12.6249 8.76667 12.0666 8.44167L8.74992 6.54167C8.19159 6.225 7.49992 6.625 7.49992 7.26667V11.0667C7.49992 11.7 8.19159 12.1083 8.74992 11.7917Z"
                                  fill="#37657F"
                                />
                              </svg>
                              <p style={{ marginLeft: "3px" }}>
                                {FinalLessonsCountLength === 1 ? "0" : ""}
                                {
                                  this.state.filtercarddata.attributes
                                    .no_of_lessons
                                }
                                {i18nJs.t("Sessions")}
                              </p>
                            </>
                          )}
                        </Grid>
                        <TooltipStyle
                          placement="bottom"
                          title={this.state.filtercarddata.attributes?.address}
                        >
                          <Grid
                            item
                            xs={12}
                            lg={3}
                            style={{ display: "flex", alignItems: "center", gap: "14px" }}
                          >
                            <svg
                              style={{ flexShrink: 0 }}
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.99998 8.99935C7.45831 8.99935 7.85081 8.83602 8.17748 8.50935C8.50359 8.18324 8.66665 7.79102 8.66665 7.33268C8.66665 6.87435 8.50359 6.48185 8.17748 6.15518C7.85081 5.82907 7.45831 5.66602 6.99998 5.66602C6.54165 5.66602 6.14942 5.82907 5.82331 6.15518C5.49665 6.48185 5.33331 6.87435 5.33331 7.33268C5.33331 7.79102 5.49665 8.18324 5.82331 8.50935C6.14942 8.83602 6.54165 8.99935 6.99998 8.99935ZM6.99998 15.1243C8.69442 13.5688 9.95137 12.1555 10.7708 10.8843C11.5903 9.61379 12 8.48546 12 7.49935C12 5.98546 11.5172 4.74574 10.5516 3.78018C9.58665 2.81518 8.40276 2.33268 6.99998 2.33268C5.5972 2.33268 4.41303 2.81518 3.44748 3.78018C2.48248 4.74574 1.99998 5.98546 1.99998 7.49935C1.99998 8.48546 2.4097 9.61379 3.22915 10.8843C4.04859 12.1555 5.30554 13.5688 6.99998 15.1243ZM6.99998 17.0202C6.88887 17.0202 6.77776 16.9993 6.66665 16.9577C6.55554 16.916 6.45831 16.8605 6.37498 16.791C4.3472 14.9993 2.83331 13.3363 1.83331 11.8018C0.833313 10.2668 0.333313 8.83268 0.333313 7.49935C0.333313 5.41602 1.00359 3.75629 2.34415 2.52018C3.68415 1.28407 5.23609 0.666016 6.99998 0.666016C8.76387 0.666016 10.3158 1.28407 11.6558 2.52018C12.9964 3.75629 13.6666 5.41602 13.6666 7.49935C13.6666 8.83268 13.1666 10.2668 12.1666 11.8018C11.1666 13.3363 9.65276 14.9993 7.62498 16.791C7.54165 16.8605 7.44442 16.916 7.33331 16.9577C7.2222 16.9993 7.11109 17.0202 6.99998 17.0202Z"
                                fill="#37657F"
                              />
                            </svg>
                            <p
                              style={{
                                marginLeft: "3px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {this.state.filtercarddata.attributes?.address}
                            </p>
                          </Grid>
                        </TooltipStyle>

                        <Grid item xs={12} lg={3} style={{ display: "flex" }}>
                          <Button
                            style={{
                              borderRadius: 8,
                              background: "transparent",
                              padding: "8px",
                              boxShadow: "none",
                              textTransform: "none",
                              lineHeight: "22px",
                              border: "1px solid #8b9aad",
                            }}
                            variant="contained"
                            data-test-id="dialog"
                            onClick={this.handleMapDialogOpen}
                          >
                            <Box
                              alignItems="center"
                              style={{ display: "flex", gap: "14px" }}
                            >
                              <img src={google_maps_img} alt="" />
                              <Box
                                style={{
                                  marginLeft: "5px",
                                  color: "#37657F",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                }}
                              >
                                {i18nJs.t("Get Directions")}
                              </Box>
                            </Box>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        spacing={2}
                        className="center-on-small right-on-large"
                      >
                        <Grid item xs={12} lg={5}>
                          <TooltipStyle
                            placement="bottom"
                            title={
                              this.state.assessmentBtnStatus ? (
                                ""
                              ) : (
                                <Box
                                  alignItems="center"
                                  alignContent="center"
                                  style={{ display: "flex" }}
                                >
                                  <img src={info_img} alt="" />
                                  <Box style={{ marginLeft: "5px" }}>
                                    {i18nJs.t(
                                      "All lessons must be completed before taking the assessment"
                                    )}
                                  </Box>
                                </Box>
                              )
                            }
                          >
                            <span>
                              <button
                                disabled={!this.state.assessmentBtnStatus}
                                data-test-id="btngotoAssessment"
                                className="btn button-assessment-test button-outline btn-assessment"
                              >
                                {this.state.assessmentBtnStatus
                                  ? this.getAssessmentLink()
                                  : i18nJs.t('Assessment Test')}
                              </button>
                            </span>
                          </TooltipStyle>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                          <button
                            data-test-id="btnsetFirstSession"
                            className="btn button-green-start-learning"
                            onClick={this.setFirstSession}
                          >
                            {i18nJs.t("Start Learning")}
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.dialogModalrender()}
                </div>

                {this.aboutDescription()}
                {this.renderImageAndDes()}
                {this.sessionListRender()}
                {this.renderReviewSection()}
              </div>
            </div>
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
    );
  }
}

const ModalStyle = styled(Box)({
  "& .modalHeader": {
    "& span": {
      fontSize: "32px",
    },
  },
  "& .closeDeleteBtn": {
    border: "none",
    cursor: "pointer",
    padding: "0",
    color: "#1C1B1F",
    width: "0",
    minWidth: "0",
    height: "40px",
    textAlign: "right",
    "& span": {
      fontSize: "32px",
    },
  },
  "& .Survey": {
    fontFamily: "Corbel V2",
    fontSize: 24,
    fontWeight: 700,
    color: "#334155",
  },
  "& .question-label": {
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 400,
    color: "#334155",
  },
  "& .textarea-survey": {
    height: "70px",
    borderRadius: "6px",
    color: "#37657F",
    width: "100%",
    resize: "none",
    outline: "none",
    border: "1px solid #CBD5E1",
    fontFamily: "Corbel V2",
    padding: 8,
    fontSize: 14,
    fontWeight: 400,
    "&::placeholder": {
      color: "#37657F",
    },
  },
  "& .main-container-survay": {
    marginTop: 24,
  },
  "& .MuiDialogContent-root:first-child": {
    paddingTop: 20,
    marginBottom: 24,
    paddingBottom: 24,
    borderBottom: "1px solid #E2E8F0",
  },
  "& .button-position": {
    padding: "0px 30px 40px 30px",
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: 0,
    paddingBottom: 30,
    "& span": {
      textTransform: "none",
    },
  },
  "& .fashion-info-button-second": {
    display: "flex",
    gap: "15px",
    maxWidth: 280,
    width: "100%",
    "& span": {
      textTransform: "none",
    },
  },
  "& .btn-one": {
    fontWeight: 700,
    width: 116,
    height: 56,
    borderRadius: 8,
  },
  "& .btn-two": {
    fontWeight: 700,
    width: 138,
    height: 56,
  },
  "& .MuiDialogContent-root": {
    padding: "8px 40px",
  },
  "& .survey-loader": {
    height: "calc(100vh - 675px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiCircularProgress-svg": {
      color: "#205e83",
    },
  },
});
const StyledRating = styled(Rating)({
  position: "relative",
  right: 10,
  "& span": {
    fontSize: 50,
    "@media (max-width: 500px)": {
      fontSize: 30,
    },
  },
  "& .MuiRating-label": {
    marginBottom: 0,
    "& :last-child": {
      marginRight: 10,
      "@media (max-width: 500px)": {
        marginRight: 0,
      },
    },
  },
  "& .MuiSvgIcon-fontSizeInherit": {
    marginLeft: 10,
  },
  "& .MuiRating-iconFilled": {
    color: "#F59E0B",
  },
  "& .MuiRating-iconHover": {
    color: "#F59E0B",
  },
  "& .MuiSvgIcon-root": {
    background: "#CBD5E1",
    padding: 10,
    borderRadius: 10,
  },
});
const BoxStyle = styled(Box)({
  "& .lesson-description-information": {
    fontFamily: "Corbel V2",
    color: "#334155",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: 8,
    border: "1px solid #CBD5E1",
    background: "#FFF",
    padding: "14px 16px",
  },
  "& .lesson-description-title, .lesson-attachment-title": {
    fontFamily: "Corbel V2",
    margin: "15px 0",
    color: "#000",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  "& .margin-80": {
    margin: "80px 0 15px",
  },
  "& .attachment-container": {
    borderRadius: 8,
    background: "#FFF",
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .text-and-icon": {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  "& .attachment-icon-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    background: "#F1F5F9",
    width: 56,
    height: 56,
  },
  "& .attachment-image-name": {
    fontFamily: "Corbel V2",
    color: "#0F172A",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .attachment-image-size": {
    fontFamily: "Corbel V2",
    color: "#37657F",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .curser-pointer": {
    cursor: "pointer",
  },
});
const StyledRatingShow = styled(Rating)({
  marginTop: 10,
  marginBottom: 10,
  display: "flex !important",
  "& span": {
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.26)",
  },
  "& .MuiRating-iconFilled": {
    color: "#F59E0B",
  },
  "& .btn-assessment": {},
  "& .MuiRating-iconHover": {
    color: "#F59E0B",
  },
});
const DummyBox=styled(Box)({
  color:"#34D399", 
  marginLeft:"5px",
   marginRight:"5px"
})
const StyledSpan=styled('span')({
   display: "inline-flex",
    alignItems: "center",
     gap: "10px"
})
