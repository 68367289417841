import React from "react";

// Customizable Area Start
import { Box, Typography, Button, Grid, withStyles,  } from "@material-ui/core";
import {Close} from "@material-ui/icons";
import { logo } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import i18n from "../../../components/src/TranslateLanguage";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props
    return <Box data-test-id="id1"  className={classes.mainWrapper}>
            <Box id="id2" className={classes.termsCondiWrapper}>
              <Grid className="id3" container alignItems="center" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                <Grid data-test-id="id4" item md={6} sm={12} className={classes.termsCol}>
                  <Box id="id5" className={classes.termsCformImageol}>
                      <Box data-test-id="id6" className={classes.loginImage}>
                          <img src={logo} width={300} alt="logo" className={classes.formImageImg}  /> 
                      </Box>
                  </Box>
                </Grid>
                <Grid className="id7" item md={6} sm={12}>
                  <Box data-test-id="id8" className={classes.termsCondiItem}>
                    <Box className={classes.closebtn} data-test-id="close-button" onClick={() => this.props.navigation.navigate('LandingPage')}>
                        <a data-test-id="id10" href="/#">
                            <Close className={classes.blackColor} />
                        </a>
                    </Box>
                    <Box data-test-id="id11" className={classes.termsCondiBlock} dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                        <Box>
                            <Typography variant="h4" className={classes.termsCondiBlockHeading}>{i18n.t('TERMS_CONDITIONS')}</Typography>
                            <Typography variant="subtitle1" className={classes.termsCondiBlockSubHeading}>{i18n.t('AGREEMENTS')}</Typography>
                        </Box>
                        {
                          this.state.flag ? (
                            <Box data-test-id="id12" className={`${classes.termsCondiBlockContent} ${classes.loaderClass}`}>
                              <CircularProgress />
                            </Box>) : 
                            (<Box data-test-id="id12" className={classes.termsCondiBlockContent}>
                        
                          {
                            this.state.termsCondsData.map((item: any, index: number) => {
                              return(
                                <Typography id="id13" key={index} variant="body1" className={classes.termsCondiBlockInnerContent} dangerouslySetInnerHTML={{ __html: item?.description }}></Typography>
                              )
                            })
                          }
                        </Box>
                          )
                        }
                        
                        <Box className={classes.termsCondiButton}>
                            <Button variant="contained" data-test-id="i-agree-button" 
                            onClick={() => this.onClickRedirect()}
                            // onClick={() => 
                            //  const value = await getStorageData('role');
                            //   // this.props.navigation.navigate('PrivacyPolicy')
                            //   this.props.history.push({
                            //     pathname: '/PrivacyPolicy',
                            //     state: { data: this.props.location.state.data}
                            // })
                            //   }
                               className={classes.termsCondiButtonBtn} >
                                {i18n.t('I_AGREE')}
                            </Button>
                            <Button data-test-id="decline-button" onClick={() => this.props.navigation.navigate('LandingPage')} variant="outlined"  className={classes.termsCondiButtonBtnOutline}>
                              {i18n.t('DECLINE')}
                            </Button>
                        </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
    
    // Customizable Area End
  }
}

// Customizable Area Start

export const webStyles = {
  mainWrapper: {
    padding: "48px",
    backgroundColor: "rgba(55, 101, 127, 0.2)",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    minHeight: "100vh",
    '@media (max-width: 991px)': {
      padding: "0",
    },
  },
  blackColor: {
    fill: "black"
  },
  closebtn: {
    position: 'absolute' as const,
    right: '40px',
    top: '0px',
    background:' rgba(217, 217, 217, 0.50)',
    width: '35px',
    height: '35px',
    textAlign: 'center' as const,
    borderRadius:' 5px',
    display:' flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  termsCondiWrapper: {
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "#fff",
  },
  termsCol: {
    width: "100%",
  },
  termsCformImageol: {
    textAlign: "center" as const,
    padding: "60px",
    backgroundColor: "#37657f",
    height: "100%",
    borderRadius: "20px 0 0 20px",
    '@media (max-width: 991px)': {
      borderRadius: "20px 20px 0 0",
    }
  },
  formImageImg: {
    maxWidth: "300px",
    width: "100%",
  },
  loginImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "78vh",
    backgroundColor: "#fff",
    borderRadius: 14,
    width: "100%",
    maxHeight: "78vh",
    '@media (max-width: 575px)': {
      minHeight: "60vh",
    }
  },
  termsCondiItem: {
    // height: "100%",
    position: "relative" as const,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  termsCondiBlock: {
    position: "relative" as const,
    margin: "auto",
    padding: "70px 0",
    maxWidth: "80%",
    '@media (max-width: 991px)': {
      maxWidth: "90%",
    }
  },
  termsCondiBlockHeading: {
    fontWeight: "bold" as const,
    margin: "0 0 8px",
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: 32,
    fontStyle: "normal",
  },
  termsCondiBlockSubHeading: {
    fontSize: 16,
    fontFamily: "Corbel V2",
    fontStyle: "normal",
    color: "#000",
    fontWeight: "normal" as const,
  },
  termsCondiBlockContent: {
    margin: "40px 0",
    height: "calc(100vh - 520px)",
    overflowX:"hidden" as const,
    overflowY:"auto" as const,
    padding: "10px",
    '&::-webkit-scrollbar': {
      width: '5px',
  },
  '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
      background: 'rgb(32, 94, 131, 0.5)',
      borderRadius: '10px',
  }
  },
  loaderClass: {
    display : 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  termsCondiButton: {
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    display: "flex",
    padding: "20px 0 0",
  },
  termsCondiBlockInnerContent: {
    fontSize: 16,
    fontStyle: "normal",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 30px",
    fontFamily: "Corbel V2",
    fontWeight: "normal" as const,
    "& br":{
      display:"none"
    },
    "& li":{
      marginBottom:10
    }
  },
  termsCondiButtonBtn: {
    backgroundColor: "#37657f",
    color: "#fff",
    textTransform: 'capitalize' as const,
    borderRadius: 8,
    border: "1px solid #37657f",
    width: "100%",
    padding: "10px",
    fontFamily: 'Corbel V2',
    letterSpacing: 0.5,
    cursor: "pointer",
    "& span":{
      fontSize: 16,
      fontWeight: 700,
    },
    "&:hover" : {
      color : "#37657f",
      backgroundColor: 'transparent'
    }
  },
  termsCondiButtonBtnOutline: {
    color: "#37657f",
    background: "rgba(213, 225, 255, 0.5)",
    textTransform: 'capitalize' as const,
    padding: "10px",
    fontWeight: 600,
    borderRadius: 8,
    border: "1px solid #37657f",
    fontFamily: 'Corbel V2',
    letterSpacing: 0.5,
    width: "100%",
    cursor: "pointer",
    "& span":{
      fontSize: 16,
      fontWeight: 700,
    },
  }
}
//@ts-ignore
export const TermsConditionsStyles = withRouter(withStyles(webStyles)(
  TermsConditions
));
// Customizable Area End
