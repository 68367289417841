Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_categories/categories";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getMostPopularCoursesApi = "bx_block_profile/courses/landing_page_index"
exports.getTutorialDataLanding = "bx_block_tutorials2/tutorials";
exports.testimonialAPIEndPoint = "bx_block_categories/testimonials";


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End