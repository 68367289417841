import React from 'react';
import { logo } from './assets';
import ForgotPasswordController, { Props } from './ForgotPasswordController';
import OtpInput from 'react-otp-input';
import i18n from '../../../components/src/TranslateLanguage';

export default class PasswordReset extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <>
                <div className="main-wrapper">
                    <div className="signin-form-wrapper align-center" data-test-id="signin-form-wrapper-id1" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                        <div className="col-6">
                            <div className="form-image">
                                <div className="login-image">
                                    <img src={logo} alt="logo" width={300} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 signup-item">
                            <div className="close-btn t-40">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                    </svg>
                                </a>
                            </div>
                            <div className="login-form-wrapper" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                                <form
                                 onSubmit=
                                 {(values) => {
                                    if (this.state.otp) {
                                        this.setState({ otpError: false })
                                        values.preventDefault(); this.submitOtp(this.state.otp)
                                    } {
                                        this.setState({ otpError: true })
                                    }
                                      }}>
                                    <div className="block-heading">
                                        <h4>{i18n.t('PASSWORD_RESET')}</h4>
                                        <p>{i18n.t('WE_SENT_A_CODE_TO_RAFFLES')}</p>
                                    </div>
                                    <div className="block-content">
                                        <div className="form-group otp-block" dir='ltr'>
                                            <label htmlFor="code" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                                                {i18n.t('ENTER_CODE')}
                                                </label>
                                            <OtpInput
                                                value={this.state.otp}
                                                inputType="number"
                                                data-test-id="OtpField"
                                                shouldAutoFocus={true}
                                                onChange={(value: any) => this.setState({ otp: value })}
                                                numInputs={6}
                                                renderSeparator={<span></span>}
                                                renderInput={(props: any) => <input name='otp' data-test-id='otpinput' {...props} />}
                                                inputStyle={{
                                                    // width: "30px",
                                                    // minWidth: "40px",
                                                    // height: "45px",
                                                    // background: "none",
                                                    marginRight: "12px",
                                                    // border: "1px solid #2DE2D5",
                                                    opacity: "100%",
                                                    fontSize: "24px",
                                                    minWidth: "66px",
                                                    height: "80px",
                                                    border: "1px solid rgba(0, 0, 0, 0.2)",
                                                    background: "#FFF",
                                                    borderRadius: "10px",
                                                }} />
                                            
                                        </div>
                                        
                                        <span className='send-span'>{i18n.t('DIDNT_GET_CODE')}
                                            <a href="#">{i18n.t('SEND_AGAIN')}</a>
                                        </span>
                                        <a href="#">{i18n.t('CALL_ME_INSTEAD')}</a>
                                        <div className="form-group">
                                            {/* <Link to='/NewPassword' style={{ textDecoration : 'none'}}> */}
                                            <button
                                                data-test-id="submitbutton"
                                                onClick={
                                                    () => {
                                                        if (this.state.otp) {
                                                            this.setState({ otpError: false })
                                                        } {
                                                            this.setState({ otpError: true })
                                                        }
                                                    }
                                                }
                                                type="submit" className="btn button  mt-35" disabled={this.state.otp?.length == 6 ? false : true}>{i18n.t('CONTINUE')}</button>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
